.js-step {
    display: none;
    bottom: 0;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
}

.js-calculation-form {


}

.step__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: var(--color-gold);
    padding: 0 0 0 10px;

}

.step__line {
    display: flex;
    width: 100%;
    height: 3px;
    background: var(--color-gold);
}

.calculations__form {

    &__select {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;

        .calc__select {
            display: flex;
            max-width: 300px;
        }
    }

    &__input {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;

        .calc__wrap__input {
            display: flex;
            flex-direction: column;
            max-width: 295px;
            width: 100%;
        }

        .calc__label {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            align-items: center;
            margin-bottom: 5px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */

        input[type=number] {
            -moz-appearance: textfield;
        }

        input {
            display: flex;
            height: 50px;
            padding-left: 20px;
            box-sizing: border-box;
            margin: 0;
            box-shadow: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 0;
            border: 1px solid var(--color-black);
            background-color: var(--color-white);
            background-repeat: no-repeat, repeat;
            background-position: right .7em top 50%, 0 0;
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0111032 9H1.4511V3.096L4.3671 6.858H4.4211L7.2291 3.096V9H8.6691V0.9H7.3911L4.4391 4.914L1.3251 0.9H0.0111032V9Z' fill='black'/%3E%3C/svg%3E%0A");

            &:focus {
                outline: none;
            }
        }
    }


    &__wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    &__left {
        max-width: 610px;
        width: 100%;
        margin-right: 15px;

        .step__title {
            margin-top: 40px;
        }

        .step__label {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            align-items: center;
            color: #856B5D;
            margin-top: 40px;
        }

    }

    &__right {
        width: 100%;
        max-width: calc(100% - 625px);
        border-left: 3px solid var(--color-gold);

        &__top {
            border-bottom: 3px solid var(--color-gold);
            padding-left: 30px;
            min-height: 96px;


            &.result {
                display: flex;
                align-items: flex-end;
            }

            .data__info {
                font-size: 36px !important;
                font-weight: bold !important;

                span {
                }
            }
        }


        &__bottom {
            padding-left: 30px;
            padding-top: 40px;
        }


        .title__info {
            padding: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: baseline;
            text-transform: uppercase;

            color: var(--color-gold);
            margin: 0 0 15px;

        }

        .data__info {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            display: flex;
            align-items: baseline;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 15px;

            span {
                font-size: 36px;
                font-weight: bold;
            }
        }

    }

    &__nav {
        display: flex;
        margin-top: 60px;

        .btn__calc {
            margin-left: 625px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 1;
            display: flex;
            align-items: center;
            text-align: center;
            color: #856B5D;
            border: 1px solid var(--color-gold);
            width: 190px;
            height: 50px;
            justify-content: center;
            text-transform: uppercase;

        }
    }
}
