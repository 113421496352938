.btn {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

.btn__menu {
    background: var(--color-red);
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-weight: bold;
    font-size: 18px;
    color: var(--color-white);

}

.btn__menu:hover {
    background: var(--color-black);
}

.btn__slider__link, .btn__form {
    position: relative;
    background: var(--color-red);
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: var(--color-white);
    width: 150px;
    height: 34px;
}

.btn__slider__link:hover {
    background: transparent;
    color: var(--color-white);
    border: 2px solid var(--color-gold);
    background: var(--color-gold)
}

.btn__form:hover {
    background: transparent;
    color: var(--color-black);
    border: 2px solid var(--color-black);
    background: var(--color-gold)
}

.btn__form:disabled {
    cursor: default;
    opacity: .5;
    user-select: none;
}

.btn__link {
    position: relative;
    background: transparent;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: var(--color-black);
    width: 150px;
    height: 34px;
    border: 2px solid var(--color-black);
}
.btn__link:hover {
    color: var(--color-white);
    border: 2px solid var(--color-red);
    background: var(--color-red)
}
