form {
    .form-inputs {
        margin-bottom: 10px;
        /* Change Autocomplete styles in Chrome*/
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border: none;
            -webkit-text-fill-color: $color-black;
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;
        }
        .form-control {
            padding: 10px;
            width: 100%;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: var(--color-black);
            border: none;
            outline: none;
            background-color: var(--color-white);
            &::placeholder {
                color: #a4a7a9;
            }

        }
    }
    textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 12px;
        border-radius: 0;
        color: var(--color-black);
        background-color: var(--color-white);
        border: none;
        resize: none;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        outline: none;
    }
}
.btn__form {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-stretch: normal;
    outline: none;
    cursor: pointer;
    width: 200px;
    height: 40px;
    margin: 0 auto;

}
