/* class applies to select element itself, not a wrapper element */
.select-css {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--color-black);
    padding-left: 50px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    box-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 50px;
    border-radius: 0;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 18L20.4593 0.75H0.540708L10.5 18Z' fill='black'/%3E%3C/svg%3E%0A");
    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.828' height='12.243' viewBox='0 0 18.828 12.243'%3E%3Cpath id='Контур_60927' data-name='Контур 60927' d='M1022.79-4302.8l8,8-8,8' transform='translate(-4285.387 -1021.375) rotate(90)' fill='none' stroke='%23a5a5a5' stroke-miterlimit='10' stroke-width='4'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat, repeat;
    background-position: left .7em top 50%, 0 0;
    //background-size: 18px auto, 100%;
}
.select-css::-ms-expand {
    display: none;
}
.select-css:hover {
}
.select-css:focus {
    box-shadow: 0 0 1px 1px rgb(38, 38, 38);
    box-shadow: 0 0 0 1px -moz-mac-focusring;
    outline: none;
}

.select-css option {
    font-weight:normal;
}

*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}

.select-css:disabled, .select-css[aria-disabled=true] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
}
