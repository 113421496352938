.popup {
    position: relative;
    margin: 0 auto;
    padding: 60px;
    max-width: 600px;
    width: 100%;
    border-radius: 0;
    background-color: var(--color-gold);

    &__inner {
        position: relative;
        margin: 0 auto;
    }

    &__btn-close {
        position: absolute;
        top: 40px;
        right: 30px;
        cursor: pointer;
        color: var(--color-gold);

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background: url("../images/icons/popup_close.svg") no-repeat center;
        }

    }

    .form-wrap {
        position: relative;
        margin: 0 auto;

        .invalid-feedback {
            display: none;
        }
    }
}

.popup-question {

}

.popup-success {
    padding: 60px;
    max-width: 600px;

    &__title {
        margin-bottom: 48px;
        text-align: center;
        color: var(--color-white);
        font-size: 40px;
    }

    &__text {
        font-size: 24px;
        margin-bottom: 60px;
        text-align: center;
        color: var(--color-white);
    }

    &__link {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .btn-form {
        font-weight: 500;
    }
}


body.mfp-active {
    overflow: hidden;

    .mfp-container {
        overflow: auto;
    }
}


