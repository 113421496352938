/* Page */
body {
    min-width: 980px;
}

.page,
.page__body {
    height: 100%;
}

.page {
    color: var(--color-black);
    font-family: 'Circe', sans-serif;
    background-color: var(--color-white);
    line-height: 1;
}

.page__body {
    max-width:  1440px;
    margin: 0 auto;
}

.hidden {
    display: none !important;
}


