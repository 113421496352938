.documents {
    &__container {
        position: relative;
        display: flex;
    }

    &__list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
    }

    &__element {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0;
    }

    &__link {

        font-weight: normal;
        font-size: 18px;
        line-height: 1;
        display: flex;
        align-items: center;
        padding: 15px 0;
        color: var(--color-black);

        &:hover {
            font-weight: bold;
        }
    }
}
