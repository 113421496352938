@import "vendor/init.css";

@import "base/init.css";

@import "components/init.css";


:root {
    --color-red: #CB2C30;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-black-light: #111820;
    --color-black-light-2: #262626;
    --color-gold: #AE8567;
    --color-grey: #E5E5E5;
    --color-grey-light: #F2F2F2;

}
.anchor-wrap {
    position: relative;
}
.anchor {
    position: absolute;
    top: -20px;
}
