.catalog__menu {

    &--footer {
        .catalog__menu__element {
            height: 120px;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--color-gold);
            width: 75%;
            @media (max-width: 1200px) {
                height: 85px;
            }
            &:first-of-type {
                padding-left: 100px;
                width: 40%;
            }

            &:hover {
                background: var(--color-red);
                color: var(--color-white);

                .catalog__menu__link {
                    border-left: 4px solid var(--color-white);
                    color: var(--color-white);
                }
            }
        }
    }


}
