/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}
.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}
.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }
.mfp-align-top .mfp-container:before {
  display: none; }
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }
.mfp-ajax-cur {
  cursor: progress; }
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out; }
.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in; }
.mfp-auto-cursor .mfp-content {
  cursor: auto; }
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }
.mfp-loading.mfp-figure {
  display: none; }
.mfp-hide {
  display: none !important; }
.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
.mfp-preloader a {
    color: #CCC; }
.mfp-preloader a:hover {
      color: #FFF; }
.mfp-s-ready .mfp-preloader {
  display: none; }
.mfp-s-error .mfp-content {
  display: none; }
button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }
button::-moz-focus-inner {
  padding: 0;
  border: 0; }
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
.mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
.mfp-close:active {
    top: 1px; }
.mfp-close-btn-in .mfp-close {
  color: #333; }
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
.mfp-arrow:active {
    margin-top: -54px; }
.mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
.mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }
.mfp-arrow-left {
  left: 0; }
.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }
.mfp-arrow-right {
  right: 0; }
.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
.mfp-iframe-holder .mfp-close {
    top: -40px; }
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }
/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }
/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
.mfp-figure figure {
    margin: 0; }
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }
.mfp-image-holder .mfp-content {
  max-width: 100%; }
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }
@media all and (max-width: 900px) {
  .mfp-arrow {
    transform: scale(0.75); }
  .mfp-arrow-left {
    transform-origin: 0; }
  .mfp-arrow-right {
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
.mfp-iframe-holder .mfp-content {
  max-width: 70vw;
  max-height: 80vh;
}
.slick-slide {
  font-size: 0;
}
.slick-center {
  font-size: 3em;
}
.slick-dots {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.slick-dots li {
        text-align: center;
        margin-bottom: 17px;
        margin-top: 17px;
    }
.slick-dots button {
        display: inline-block;
        padding: 0;
        width: 13px;
        height: 13px;
        background-color: var(--color-red);
        border-radius: 50%;
        font-size: 0;
        line-height: 0;
        outline: none;
        border: none;
        cursor: pointer;
    }
.slick-active button {
        width: 20px;
        height: 20px;
        background-color: var(--color-red);
    }
@font-face {
  font-family: 'Circe';
  src: url(/packs/media/images/13bbfad0e7f851273f3c.eot);
  src: local('Circe'), local('Circe-Regular'),
  url(/packs/media/images/13bbfad0e7f851273f3c.eot?#iefix) format('embedded-opentype'),
  url(/packs/media/images/b3583e41e063203a7e74.woff) format('woff'),
  url(/packs/media/images/814e4dd4329ffdb6c9ed.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Circe';
  src: url(/packs/media/images/e4ca0f7a178447eed594.eot);
  src: local('Circe Light'), local('Circe-Light'),
  url(/packs/media/images/e4ca0f7a178447eed594.eot?#iefix) format('embedded-opentype'),
  url(/packs/media/images/0a23696bc00e1131f434.woff) format('woff'),
  url(/packs/media/images/61755338a0e56c46dab8.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Circe';
  src: url(/packs/media/images/23b8280ab15052d5ba11.eot);
  src: local('Circe Bold'), local('Circe-Bold'),
  url(/packs/media/images/23b8280ab15052d5ba11.eot?#iefix) format('embedded-opentype'),
  url(/packs/media/images/8a1c4d2d35cdd2d6704b.woff) format('woff'),
  url(/packs/media/images/63396ee791f60623acbf.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
img {
    max-width: 100%;
    height: auto;
}
main, section, article, header {
    display: block;
}
a {
    background-color: transparent;
    text-decoration: none;
}
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
            clip-path: inset(100%);
}
/* Page */
body {
    min-width: 980px;
}
.page,
.page__body {
    height: 100%;
}
.page {
    color: var(--color-black);
    font-family: 'Circe', sans-serif;
    background-color: var(--color-white);
    line-height: 1;
}
.page__body {
    max-width:  1440px;
    margin: 0 auto;
}
.hidden {
    display: none !important;
}
.btn {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}
.btn__menu {
    background: var(--color-red);
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-weight: bold;
    font-size: 18px;
    color: var(--color-white);

}
.btn__menu:hover {
    background: var(--color-black);
}
.btn__slider__link, .btn__form {
    position: relative;
    background: var(--color-red);
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: var(--color-white);
    width: 150px;
    height: 34px;
}
.btn__slider__link:hover {
    background: transparent;
    color: var(--color-white);
    border: 2px solid var(--color-gold);
    background: var(--color-gold)
}
.btn__form:hover {
    background: transparent;
    color: var(--color-black);
    border: 2px solid var(--color-black);
    background: var(--color-gold)
}
.btn__form:disabled {
    cursor: default;
    opacity: .5;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.btn__link {
    position: relative;
    background: transparent;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: var(--color-black);
    width: 150px;
    height: 34px;
    border: 2px solid var(--color-black);
}
.btn__link:hover {
    color: var(--color-white);
    border: 2px solid var(--color-red);
    background: var(--color-red)
}
form .form-inputs {
        margin-bottom: 10px;
    }
/* Change Autocomplete styles in Chrome*/
form .form-inputs input:-webkit-autofill,
        form .form-inputs input:-webkit-autofill:hover,
        form .form-inputs input:-webkit-autofill:focus,
        form .form-inputs textarea:-webkit-autofill,
        form .form-inputs textarea:-webkit-autofill:hover,
        form .form-inputs textarea:-webkit-autofill:focus,
        form .form-inputs select:-webkit-autofill,
        form .form-inputs select:-webkit-autofill:hover,
        form .form-inputs select:-webkit-autofill:focus {
            border: none;
            -webkit-text-fill-color: $color-black;
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            -webkit-transition: background-color 5000s ease-in-out 0s;
            transition: background-color 5000s ease-in-out 0s;
        }
form .form-inputs .form-control {
            padding: 10px;
            width: 100%;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: var(--color-black);
            border: none;
            outline: none;
            background-color: var(--color-white);

        }
form .form-inputs .form-control::-moz-placeholder {
                color: #a4a7a9;
            }
form .form-inputs .form-control:-ms-input-placeholder {
                color: #a4a7a9;
            }
form .form-inputs .form-control::placeholder {
                color: #a4a7a9;
            }
form textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 12px;
        border-radius: 0;
        color: var(--color-black);
        background-color: var(--color-white);
        border: none;
        resize: none;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        outline: none;
    }
.btn__form {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-stretch: normal;
    outline: none;
    cursor: pointer;
    width: 200px;
    height: 40px;
    margin: 0 auto;

}
.mt-35{
    margin-top: 35px;
}
.header {
    position: fixed;
    height: auto;
    width: 100%;
    max-width: 1440px;
    top: 60px;
    z-index: 1000;
    display: grid;
    margin: 0 auto;
}
@media (max-width: 1200px) {
.header {
        top: 30px
}
    }
.header__logo {
        display: none;
    }
.header__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
.header__menu {
        display: flex;
        width: 90%;
        height: 80px;
        background-color: var(--color-red);
        border-bottom-right-radius: 40px;
        border-top-right-radius: 40px;
        opacity: 1;
        transition: width 0.3s ease-out, opacity 0.3s ease-out, height 0.7s ease;
    }
.header__menu--closed {
            width: 0;
            height: 0;
            opacity: 0;
        }
.header__menu--closed .header__nav {
                margin-left: -500px;
                transition: margin-left 0.3s ease-out;
            }
.header__menu--closed .header__list {
                margin-left: -500px;
                transition: margin-left 0.3s ease-out;
            }
.header__button {
        position: relative;
        display: flex;
        list-style: none;
        width: 80px;
        height: 80px;
        margin-right: 100px;
        margin-left: 40px;
        z-index: 999;
        right: 0;
        top: 0;
    }
@media (max-width: 1200px) {
.header__button {
            margin-right: 40px
    }
        }
.header__button--closed {
            display: flex;
            position: absolute;
            right: 100px;
            top: 0;
            margin-right: 0;
            margin-left: 40px;
        }
@media (max-width: 1200px) {
.header__button--closed {
                right: 40px
        }
            }
.header__nav {
        display: flex;
        align-items: center;
        width: 100%;
        transition: all 0.5s ease-out;
    }
.header__list {
        transition: all 0.5s ease-out;
        margin: 0 40px;
        width: 100%;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        line-height: 1;
        font-size: 18px;
        font-weight: bold;
    }
.header__list li {
            margin-right: auto;
            margin-left: auto;
            line-height: 1;

        }
.header__list li:first-of-type {
                margin-left: 0;
            }
.header__list li:last-of-type {
                margin-right: 0;
            }
.header__list a {
            text-transform: uppercase;
            display: inline-block;
            font-stretch: normal;

            letter-spacing: normal;
            text-align: left;
            text-decoration: none;
            padding-top: 7px;
            padding-bottom: 7px;
            color: var(--color-white);
        }
.header__list a:hover, .header__list a:focus, .header__list a.active {
                text-decoration: underline;
            }
.products__show .header, .products__specifications .header, .products__docs .header, .products__additionally .header, .selections__index .header, .calculations__index .header, .calculations__search .header {
        position: relative;
        height: 150px;
        width: 100%;
        display: grid;
        margin: 0 auto;
        top: auto;
        padding-top: 35px;
        padding-bottom: 35px;
        background: var(--color-red);
    }
.products__show .header__logo, .products__specifications .header__logo, .products__docs .header__logo, .products__additionally .header__logo, .selections__index .header__logo, .calculations__index .header__logo, .calculations__search .header__logo {
            display: block;
            position: absolute;
            left: 100px;
            top: 0;
            z-index: 100;
        }
.products__show .header__logo--closed, .products__specifications .header__logo--closed, .products__docs .header__logo--closed, .products__additionally .header__logo--closed, .selections__index .header__logo--closed, .calculations__index .header__logo--closed, .calculations__search .header__logo--closed {
                z-index: -1;
            }
@media (max-width: 1200px) {
.products__show .header__logo, .products__specifications .header__logo, .products__docs .header__logo, .products__additionally .header__logo, .selections__index .header__logo, .calculations__index .header__logo, .calculations__search .header__logo {
                left: 40px
        }
            }
.products__show .header__container, .products__specifications .header__container, .products__docs .header__container, .products__additionally .header__container, .selections__index .header__container, .calculations__index .header__container, .calculations__search .header__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }
.products__show .header__menu, .products__specifications .header__menu, .products__docs .header__menu, .products__additionally .header__menu, .selections__index .header__menu, .calculations__index .header__menu, .calculations__search .header__menu {
            display: flex;
            width: 90%;
            height: 80px;
            background-color: var(--color-red);
            border-bottom-right-radius: 40px;
            border-top-right-radius: 40px;
            opacity: 1;
            transition: width 0.3s ease-out, opacity 0.3s ease-out, height 0.7s ease;
        }
.products__show .header__menu--closed, .products__specifications .header__menu--closed, .products__docs .header__menu--closed, .products__additionally .header__menu--closed, .selections__index .header__menu--closed, .calculations__index .header__menu--closed, .calculations__search .header__menu--closed {
                width: 0;
                height: 0;
                opacity: 0;
            }
.products__show .header__menu--closed .header__nav, .products__specifications .header__menu--closed .header__nav, .products__docs .header__menu--closed .header__nav, .products__additionally .header__menu--closed .header__nav, .selections__index .header__menu--closed .header__nav, .calculations__index .header__menu--closed .header__nav, .calculations__search .header__menu--closed .header__nav {
                    margin-left: -500px;
                    transition: margin-left 0.3s ease-out;
                }
.products__show .header__menu--closed .header__list, .products__specifications .header__menu--closed .header__list, .products__docs .header__menu--closed .header__list, .products__additionally .header__menu--closed .header__list, .selections__index .header__menu--closed .header__list, .calculations__index .header__menu--closed .header__list, .calculations__search .header__menu--closed .header__list {
                    margin-left: -500px;
                    transition: margin-left 0.3s ease-out;
                }
.products__show .header__button, .products__specifications .header__button, .products__docs .header__button, .products__additionally .header__button, .selections__index .header__button, .calculations__index .header__button, .calculations__search .header__button {
            position: relative;
            display: flex;
            list-style: none;
            width: 80px;
            height: 80px;
            margin-right: 100px;
            margin-left: 40px;
            z-index: 999;
            right: 0;
            top: 0;
        }
@media (max-width: 1200px) {
.products__show .header__button, .products__specifications .header__button, .products__docs .header__button, .products__additionally .header__button, .selections__index .header__button, .calculations__index .header__button, .calculations__search .header__button {
                margin-right: 40px
        }
            }
.products__show .header__button .btn__menu, .products__specifications .header__button .btn__menu, .products__docs .header__button .btn__menu, .products__additionally .header__button .btn__menu, .selections__index .header__button .btn__menu, .calculations__index .header__button .btn__menu, .calculations__search .header__button .btn__menu {
                background: var(--color-black);
                color: var(--color-white)
            }
.products__show .header__button--closed, .products__specifications .header__button--closed, .products__docs .header__button--closed, .products__additionally .header__button--closed, .selections__index .header__button--closed, .calculations__index .header__button--closed, .calculations__search .header__button--closed {
                display: flex;
                position: absolute;
                right: 100px;
                top: 0;
                margin-right: 0;
                margin-left: 40px;
            }
@media (max-width: 1200px) {
.products__show .header__button--closed, .products__specifications .header__button--closed, .products__docs .header__button--closed, .products__additionally .header__button--closed, .selections__index .header__button--closed, .calculations__index .header__button--closed, .calculations__search .header__button--closed {
                    right: 40px
            }
                }
.products__show .header__nav, .products__specifications .header__nav, .products__docs .header__nav, .products__additionally .header__nav, .selections__index .header__nav, .calculations__index .header__nav, .calculations__search .header__nav {
            display: flex;
            align-items: center;
            width: 100%;
            transition: all 0.5s ease-out;
        }
.products__show .header__list, .products__specifications .header__list, .products__docs .header__list, .products__additionally .header__list, .selections__index .header__list, .calculations__index .header__list, .calculations__search .header__list {
            transition: all 0.5s ease-out;
            margin: 0 40px 0 100px;
            width: 100%;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            line-height: 1;
            font-size: 18px;
            font-weight: bold;
        }
@media (max-width: 1200px) {
.products__show .header__list, .products__specifications .header__list, .products__docs .header__list, .products__additionally .header__list, .selections__index .header__list, .calculations__index .header__list, .calculations__search .header__list {
                margin-left: 40px
        }
            }
.products__show .header__list li, .products__specifications .header__list li, .products__docs .header__list li, .products__additionally .header__list li, .selections__index .header__list li, .calculations__index .header__list li, .calculations__search .header__list li {
                margin-right: auto;
                margin-left: auto;
                line-height: 1;

            }
.products__show .header__list li:first-of-type, .products__specifications .header__list li:first-of-type, .products__docs .header__list li:first-of-type, .products__additionally .header__list li:first-of-type, .selections__index .header__list li:first-of-type, .calculations__index .header__list li:first-of-type, .calculations__search .header__list li:first-of-type {
                    margin-left: 0;
                }
.products__show .header__list li:last-of-type, .products__specifications .header__list li:last-of-type, .products__docs .header__list li:last-of-type, .products__additionally .header__list li:last-of-type, .selections__index .header__list li:last-of-type, .calculations__index .header__list li:last-of-type, .calculations__search .header__list li:last-of-type {
                    margin-right: 0;
                }
.products__show .header__list a, .products__specifications .header__list a, .products__docs .header__list a, .products__additionally .header__list a, .selections__index .header__list a, .calculations__index .header__list a, .calculations__search .header__list a {
                text-transform: uppercase;
                display: inline-block;
                font-stretch: normal;

                letter-spacing: normal;
                text-align: left;
                text-decoration: none;
                padding-top: 7px;
                padding-bottom: 7px;
                color: var(--color-white);
            }
.products__show .header__list a:hover, .products__show .header__list a:focus, .products__show .header__list a.active, .products__specifications .header__list a:hover, .products__specifications .header__list a:focus, .products__specifications .header__list a.active, .products__docs .header__list a:hover, .products__docs .header__list a:focus, .products__docs .header__list a.active, .products__additionally .header__list a:hover, .products__additionally .header__list a:focus, .products__additionally .header__list a.active, .selections__index .header__list a:hover, .selections__index .header__list a:focus, .selections__index .header__list a.active, .calculations__index .header__list a:hover, .calculations__index .header__list a:focus, .calculations__index .header__list a.active, .calculations__search .header__list a:hover, .calculations__search .header__list a:focus, .calculations__search .header__list a.active {
                    text-decoration: underline;
                }
.footer {
    padding: 50px 100px;
    background-color: var(--color-white);
    border: none;
    display: grid;
    grid-template-columns: minmax(122px, 210px) minmax(210px, 420px) minmax(120px, 210px) minmax(250px, 400px);
}
@media (max-width: 1200px) {
.footer {
        padding: 40px 50px
}
    }
.footer--logo {
        position: relative;
    }
.footer--logo a {
            display: block;
        }
.footer--logo svg {
            width: 122px;
            height: 40px;
        }
.footer--logo svg path {
                fill: var(--color-black);
            }
.footer__menu h5 {
            margin: 0;
            padding: 0;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;

        }
.footer__menu ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 18px;
            font-weight: normal;
            line-height: 1;
        }
.footer__menu ul li {
                display: block;
                margin-bottom: 10px;
            }
.footer__menu ul li a {
                    color: var(--color-black);
                }
.footer__menu ul li a:hover {
                        text-decoration: underline;
                    }
.footer__menu--social h5 {
                text-align: center;
            }
.footer__social {
        text-align: center;

    }
.footer__social .social {
            flex-direction: row;
            align-items: center;
        }
.footer__social .social__link path {
                    fill: var(--color-black) !important;
                }
.footer__social .social__link:not(:last-of-type) {
                    margin: 0 8px 0 0;
                }
.footer__social .social__link:hover path {
                        fill: var(--color-red) !important;
                    }
.footer__oba {
        display: flex;
        width: 100%;
        justify-content: center;
    }
.footer__oba span {
            align-items: center;
            font-size: 12px;
            color: var(--color-black);
        }
.footer__oba a {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: var(--color-black);
        }
.footer__oba a span {
                margin-right: 10px;

            }
.footer__oba a svg{
                font-size: 12px;
                width: 100px;
            }
.catalog__menu--footer .catalog__menu__element {
            height: 120px;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--color-gold);
            width: 75%;
        }
@media (max-width: 1200px) {
.catalog__menu--footer .catalog__menu__element {
                height: 85px
        }
            }
.catalog__menu--footer .catalog__menu__element:first-of-type {
                padding-left: 100px;
                width: 40%;
            }
.catalog__menu--footer .catalog__menu__element:hover {
                background: var(--color-red);
                color: var(--color-white);
            }
.catalog__menu--footer .catalog__menu__element:hover .catalog__menu__link {
                    border-left: 4px solid var(--color-white);
                    color: var(--color-white);
                }
.container__slider {
    position: relative;
    max-height: 700px;
    overflow: hidden;
}
.slider-top {
    position: relative;
}
.slider-top__img-wrap {
        position: relative;
    }
.slider-top__img-wrap img {
            overflow: hidden;
        }
.slider-top__logo {
        position: absolute;
        left: 100px;
        top: 70px;
    }
@media (max-width: 1200px) {
.slider-top__logo {
            left: 50px;
            top: 30px
    }

        }
.slider-top__logo_svg {
            width: 190px;
            height: 60px;
        }
.slider-top__logo_svg path {
                fill: var(--color-white);
            }
.slider-top__body-wrap {
        position: absolute;
        height: auto;
        width: auto;
        bottom: 65px;
        left: 100px;
        display: block;


    }
@media (max-width: 1200px) {
.slider-top__body-wrap {
            left: 50px;
            bottom: 40px


    }
        }
.slider-top__line {
        display: block;
        height: 2px;
        width: 400px;
        background-color: var(--color-red);
        margin-top: 15px;
        margin-bottom: 15px;
    }
.slider-top__title {
        position: relative;
        display: block;
    }
.slider-top__title h2, .slider-top__title .text {

            font-weight: 300;
            font-size: 64px;
            line-height: 1;
            text-transform: uppercase;
            color: var(--color-white);
            margin: 0;
            padding: 0;


        }
@media (max-width: 1200px) {
.slider-top__title h2, .slider-top__title .text {
                font-size: 48px


        }

            }
.slider-top__description {
        position: relative;
        display: block;
        max-width: 820px;
    }
.slider-top__description p {
            margin: 0;
            padding: 0;

            font-weight: normal;
            font-size: 20px;
            line-height: 22px;
            color: var(--color-white);
        }
@media (max-width: 1200px) {
.slider-top__description p {
                font-size: 18px;
                line-height: 20px
        }

            }
.slider-top__link {
        display: block;
        height: auto;
        width: auto;
        position: relative;
        margin-top: 65px;
    }
.slider-top-dots {
    position: absolute;
    top: 50%;
    right: 130px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin: 0;
    list-style: none;
}
@media (max-width: 1200px) {
.slider-top-dots {
       right: 72px
}
    }
.slider-top-dots .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
.slider-top-dots .slick-dots li {
            text-align: center;
            margin-bottom: 17px;
            margin-top: 17px;
        }
.slider-top-dots .slick-dots button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
.slider-top-dots .slick-active button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
.catalog__menu {
    position: relative;
    height: 120px;
    background: var(--color-black-light);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
@media (max-width: 1200px) {
.catalog__menu {
        height: 85px
}
    }
.catalog__menu__element {
        height: 120px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--color-gold);
        width: 50%;
        order: 1;
    }
@media (max-width: 1200px) {
.catalog__menu__element {
            height: 85px
    }
        }
.catalog__menu__element.main:first-of-type {
                padding-left: 100px;
            }
.catalog__menu__element.main
{
            width: 50%;
}
.catalog__menu__element.active {
            width: 70% !important;
            background: var(--color-red);
            color: var(--color-white);
            order: 0;
            padding-left: 100px !important;

        }
.catalog__menu__element.active .catalog__menu__link {
                border-left: 4px solid var(--color-white);
                color: var(--color-white);
            }
.catalog__menu__element:hover {
            background: var(--color-red);
            color: var(--color-white);
        }
.catalog__menu__element:hover .catalog__menu__link {
                border-left: 4px solid var(--color-white);
                color: var(--color-white);
            }
.catalog__menu__link {
        border-left: 4px solid var(--color-gold);
        padding-left: 30px;
        padding-right: 30px;

        font-weight: 300;
        font-size: 36px;
        line-height: 1;
        display: flex;
        width: 100%;
        height: 90px;
        align-items: center;
        text-transform: uppercase;
        color: inherit;
        text-decoration: none;
    }
@media (max-width: 1200px) {
.catalog__menu__link {
            font-size: 24px;
            height: 85px
    }
        }
.catalog__menu.product {
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
    background: var(--color-grey-light);
    border-bottom: 2px solid var(--color-black);

}
@media (max-width: 1200px) {
.catalog__menu.product {
        margin-left: 40px;
        margin-right: 40px

}
    }
.catalog__menu.product .catalog__menu__element {
        background: var(--color-grey-light);
        color: var(--color-gold);

    }
.catalog__menu.product .catalog__menu__element:first-of-type {
            padding-left: 0 !important;
            width: 55%;

        }
.catalog__menu.product .catalog__menu__element.active {
            width: 75% !important;
            background: var(--color-white);
            color: var(--color-gold);
            order: 0;
            padding-left: 0 !important;

        }
.catalog__menu.product .catalog__menu__element.active .catalog__menu__link {
                border-left: 4px solid var(--color-gold);
                color: var(--color-gold);
                font-size: 36px;
            }
.catalog__menu.product .catalog__menu__element:hover {
            background: var(--color-red);
            color: var(--color-white);
        }
.catalog__menu.product .catalog__menu__element:hover .catalog__menu__link {
                border-left: 4px solid var(--color-gold);
                color: var(--color-white);
            }
.catalog__menu.product .catalog__menu__link {
        font-size: 18px;
    }
.icon__slides {
    position: relative;
    background: var(--color-red);
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 5px solid var(--color-gold);
}
.icon__slides.product {
        margin-left: 100px;
        margin-right: 100px;
    }
.icon__slides.product .icon__slides__wrap {
            padding-top: 0;
        }
.icon__slides__title {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;

    }
.icon__slides__title h1, .icon__slides__title .head__title {
            margin: 0;
            padding: 0;
            font-weight: 300;
            font-size: 36px;
            line-height: 1.5;
            color: var(--color-white);
            border-bottom: 2px solid var(--color-gold);
        }
.icon__slides__wrap {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
    }
.icon__slides__image {
        flex: 1 1 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
    }
.icon__slides__name {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 10px;
    }
.icon__slides__name p {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: var(--color-white);
        }
.articles--main {
        padding: 60px 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        background: var(--color-grey);
    }
@media (max-width: 1200px) {
.articles--main {
            padding: 40px
    }
        }
.articles__title {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 50px;
    }
.articles__title h1, .articles__title .head__title {
            margin: 0;
            padding: 0;

            font-weight: 300;
            font-size: 36px;
            line-height: 1.5;
            color: var(--color-black);
            border-bottom: 2px solid var(--color-gold);
        }
.articles__title h1 a, .articles__title .head__title a {
                font-weight: 300;
                font-size: 36px;
                line-height: 1.5;
                color: var(--color-black);
            }
.articles__slides {
    }
.articles .slick-track {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 100%;
    }
.articles .slick-track .slick-slide {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: stretch;
    }
.articles__card {
        display: flex;
        height: auto;
        width: auto;
        max-width: 400px;
        justify-content: center;
        align-items: stretch;
    }
@media (max-width: 1200px) {
.articles__card {
           max-width: 300px;
            width: auto
    }
        }
.articles__card__image {
            display: flex;
        }
@media (max-width: 1200px) {
.articles__card__image {
                max-width: 300px;
                width: auto;
                margin-left: 10px;
                margin-right: 10px
        }
            }
.articles__card__image a {
                display: block;
                text-decoration: none;
                color: var(--color-black);
            }
.articles__card__image a:hover {
                    text-decoration: underline;
                }
.articles__card__image a:hover img {
                        filter: grayscale(0%);
                    }
.articles__card__image img {
                -o-object-fit: cover;
                   object-fit: cover;
                filter: grayscale(50%);
            }
.articles__card__name {
            display: flex;
            background: var(--color-white);
            padding: 25px;
            color: var(--color-black);
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            max-height: 100%;
            height: 100%;
        }
@media (max-width: 1200px) {
.articles__card__name {
                font-size: 16px;
                line-height: 18px
        }
            }
.article__slides-dots {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin: 30px 0 0;
}
.article__slides-dots .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
.article__slides-dots .slick-dots li {
            text-align: center;
            margin-left: 17px;
            margin-right: 17px;
        }
.article__slides-dots .slick-dots button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
.article__slides-dots .slick-active button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
.partners {
    position: relative;
    background: var(--color-white);
    padding: 60px;

}
.partners__slides {
        display: flex;
        justify-content: center;
        align-items: center;

    }
.partners__card {
        position: relative;
        -o-object-fit: contain;
           object-fit: contain;
    }
.partners__card__image {

        }
.partners__card__body-wrap {
            position: absolute;
            height: auto;
            width: auto;
            bottom: 65px;
            left: 100px;
            display: block;
            color: var(--color-white);
        }
@media (max-width: 1200px) {
.partners__card__body-wrap {
                bottom: 40px;
                left: 50px
        }
            }
.partners__card__title {
            position: relative;
            display: block;
            max-width: 550px;


        }
.partners__card__title h2, .partners__card__title .text {

                font-weight: 300;
                font-size: 48px;
                line-height: 1;
                text-transform: uppercase;
                color: var(--color-white);
                margin: 0;
                padding: 0;
            }
@media (max-width: 1200px) {
.partners__card__title h2, .partners__card__title .text {
                    font-size: 36px
            }
                }
.partners__card__line {
            display: block;
            height: 2px;
            width: 500px;
            background-color: var(--color-red);
            margin-top: 15px;
            margin-bottom: 15px;

        }
.partners__card__description {
            text-transform: uppercase;
            max-width: 550px;
        }
.partners__card__description p {
                text-transform: uppercase;
                margin: 0;
                padding: 0;

                font-weight: bold;
                font-size: 18px;
                line-height: 1.1;
                color: var(--color-white);
            }
@media (max-width: 1200px) {
.partners__card__description p {
                    font-size: 14px
            }
                }
.partners__card__link {
            display: block;
            height: auto;
            width: auto;
            position: relative;
            margin-top: 40px;
        }
@media (max-width: 1200px) {
.partners__card__link {
                margin-top: 20px
        }
            }
.trust--main {
        padding: 60px 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        background: var(--color-white);
    }
@media (max-width: 1200px) {
.trust--main {
            padding: 40px 50px
    }
        }
.trust__title {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 50px;
    }
.trust__title h1 {
            margin: 0;
            padding: 0;

            font-weight: 300;
            font-size: 36px;
            line-height: 1.5;
            color: var(--color-black);
            border-bottom: 2px solid var(--color-gold);
            text-transform: uppercase;
        }
.trust__slides {
    }
.trust .slick-track {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 100%;
    }
.trust .slick-track .slick-slide {
        display: flex;
        height: auto;
        height: 100%;
        justify-content: center;
        align-items: stretch;
    }
.trust__card {
        display: flex;
        height: auto;
        width: auto;
        max-width: 400px;
        justify-content: center;
        align-items: stretch;
    }
.trust__card__image {
            display: flex;
        }
.trust__card__image a {
                display: block;
                text-decoration: none;
            }
.trust__card__image a:hover {
                    text-decoration: underline;
                }
.trust__card__image a:hover img {
                        filter: grayscale(0%);
                    }
.trust__card__image:hover img {
                    filter: grayscale(0%);
                }
.trust__card__image img {
                filter: grayscale(80%);
            }
.trust__card__name {
            display: flex;
            background: var(--color-white);
            padding: 25px;
            color: var(--color-black);
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            height: 100%;
        }
.trust__slides-dots {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin: 30px 0 0;
}
.trust__slides-dots .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
.trust__slides-dots .slick-dots li {
            text-align: center;
            margin-left: 17px;
            margin-right: 17px;
        }
.trust__slides-dots .slick-dots button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
.trust__slides-dots .slick-active button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
.popup {
    position: relative;
    margin: 0 auto;
    padding: 60px;
    max-width: 600px;
    width: 100%;
    border-radius: 0;
    background-color: var(--color-gold);
}
.popup__inner {
        position: relative;
        margin: 0 auto;
    }
.popup__btn-close {
        position: absolute;
        top: 40px;
        right: 30px;
        cursor: pointer;
        color: var(--color-gold);

    }
.popup__btn-close::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background: url(/packs/media/images/d6ebef4ff63dcf86c035.svg) no-repeat center;
        }
.popup .form-wrap {
        position: relative;
        margin: 0 auto;
    }
.popup .form-wrap .invalid-feedback {
            display: none;
        }
.popup-question {

}
.popup-success {
    padding: 60px;
    max-width: 600px;
}
.popup-success__title {
        margin-bottom: 48px;
        text-align: center;
        color: var(--color-white);
        font-size: 40px;
    }
.popup-success__text {
        font-size: 24px;
        margin-bottom: 60px;
        text-align: center;
        color: var(--color-white);
    }
.popup-success__link {
        text-decoration: underline;
    }
.popup-success__link:hover {
            text-decoration: none;
        }
.popup-success .btn-form {
        font-weight: 500;
    }
body.mfp-active {
    overflow: hidden;
}
body.mfp-active .mfp-container {
        overflow: auto;
    }
.pages__title h1, .pages__title .head__title {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            color: var(--color-red);
        }
.pages__container {
        padding: 50px 100px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: space-between;
        justify-content: flex-start;
        align-items: flex-start;

    }
@media (max-width: 1200px) {
.pages__container {
            padding: 40px 50px

    }
        }
.pages__left-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;

        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 300px;
    }
@media (max-width: 1200px) {
.pages__left-block {
            flex-basis: 250px
    }
        }
.pages__left-block ul {
            margin: 30px 0 0;
            padding: 0;
            list-style: none;
            width: 240px;

        }
.pages__left-block ul .top__list__block {
                display: none;
                margin-top: 0;
                margin-left: 10px;
                -webkit-animation: pulse 1s;
                        animation: pulse 1s;
            }
.pages__left-block ul .top__list__block a {
                    font-weight: normal !important;
                }
.pages__left-block ul .top__list__block li:last-child {
                        border: none;
                    }
.pages__left-block ul .top__list__block li.active a {
                    font-weight: bold !important;
                }
.pages__left-block ul .top__list__block.active {
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;
                    -webkit-animation: fadeIn 1s;
                            animation: fadeIn 1s;
                }
.pages__left-block ul li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
.pages__left-block ul li:hover a, .pages__left-block ul li:hover button, .pages__left-block ul li.active a, .pages__left-block ul li.active button {
                        font-weight: bold;
                    }
.pages__left-block ul li:hover, .pages__left-block ul li.active
{

                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.pages__left-block ul li a, .pages__left-block ul li button {
                    padding: 15px 0;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    width: 260px;

                }
@media (max-width: 1200px) {
.pages__left-block ul li a, .pages__left-block ul li button {
                        font-size: 20px;
                        width: 230px

                }
                    }
.pages__left-block ul.bottom__list {
                margin-top: 100px;
            }
.pages__left-block ul.bottom__list li {
                    border: none;
                }
.pages__left-block ul.bottom__list li:hover {
                    //border: none;
                    }
.pages__left-block ul.bottom__list .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: var(--color-black-light);
                    text-transform: uppercase;
                    border-bottom: 2px solid var(--color-black-light);
                    margin-bottom: 30px;
                }
@media (max-width: 1200px) {
.pages__left-block ul.bottom__list .title {
                        font-size: 20px;
                        line-height: 24px
                }
                    }
.pages__left-block ul.bottom__list .btn__list__nav {
                    padding: 15px 0;
                    text-align: left;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    width: 100%;

                }
.pages__left-block ul.bottom__list .btn__list__nav.active {
                        font-weight: bold;
                        border-bottom: 2px solid var(--color-black);
                        margin-bottom: 10px;
                        margin-top: 30px;
                    }
.pages__left-block ul.bottom__list .list__block {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    margin-bottom: 10px;
                    -webkit-animation: fadeIn 0.2s;
                            animation: fadeIn 0.2s;
                }
.pages__left-block ul.bottom__list .list__block .list__block__menu {
                        margin: 0;
                        padding: 0;
                        height: 0;
                        display: flex;
                        opacity: 0;
                        visibility: hidden;
                    }
.pages__left-block ul.bottom__list .list__block .list__block__menu li {
                            margin: 0;
                            padding: 0;
                            width: 0;
                        }
.pages__left-block ul.bottom__list .list__block.active {
                        -webkit-animation: fadeIn 0.5s;
                                animation: fadeIn 0.5s;
                        border-bottom: none;
                        margin-bottom: 0;
                    }
.pages__left-block ul.bottom__list .list__block.active .list__block__menu {
                            -webkit-animation: fadeIn 1s;
                                    animation: fadeIn 1s;
                            height: auto;
                            opacity: 1;
                            visibility: visible;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                        }
.pages__left-block ul.bottom__list .list__block.active .list__block__menu li {
                                width: 100%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                display: block;
                            }
.pages__left-block ul.bottom__list .list__block.active .list__block__menu li a {
                                    display: block;
                                    padding: 15px 0;
                                    font-weight: normal;
                                    font-size: 18px;
                                    line-height: 1;
                                    align-items: center;
                                    text-transform: uppercase;
                                    width: 100%;
                                    color: var(--color-black);
                                }
.pages__left-block ul.bottom__list .list__block.active .list__block__menu li:hover a, .pages__left-block ul.bottom__list .list__block.active .list__block__menu li.active a {
                                        font-weight: bold;
                                    }
.pages__left-block ul.bottom__list .list__block.active .list__block__menu li.active {
                                    -webkit-animation: pulse 0.2s;
                                            animation: pulse 0.2s;
                                //border-bottom: 2px solid var(--color-black);

                                }
.pages__left-block ul.bottom__list .list__block:hover .btn__list__nav {
                            font-weight: bold;
                        }
.pages__left-block ul.bottom__list .list__element {
                    margin-bottom: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;
                }
.pages__left-block ul.bottom__list .list__element:hover a, .pages__left-block ul.bottom__list .list__element.active a {
                            font-weight: bold;
                        }
.pages__left-block ul.bottom__list .list__element.active {
                        -webkit-animation: pulse 0.2s;
                                animation: pulse 0.2s;
                        border-bottom: 2px solid var(--color-black);

                    }
.pages__left-block ul.bottom__list .list__element a {
                        display: block;
                        padding: 15px 0;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 1;
                        align-items: center;
                        text-transform: uppercase;
                        width: 100%;
                        color: var(--color-black);
                    }
.pages__left-block .pages__title {
            border-bottom: 2px solid var(--color-red);
            min-width: 240px;
        }
.pages__left-block h1, .pages__left-block .head__title {

            font-weight: bold;
            font-size: 32px;
            line-height: 1.1;
            text-transform: uppercase;
        }
@media (max-width: 1200px) {
.pages__left-block h1, .pages__left-block .head__title {
                font-size: 26px
        }
            }
.pages__right-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding-left: 15px;

    }
.pages__right-block .pages__title {
            margin-bottom: 30px;
        }
.pages__right-block h1 {

            font-weight: 300;
            font-size: 32px;
            line-height: 1.1;
            text-transform: uppercase;
            color: #000000;
        }
@media (max-width: 1200px) {
.pages__right-block h1 {
                font-size: 26px;
                line-height: 24px
        }
            }
.pages__body {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 820px;

    }
.pages__body h2, .pages__body p, .pages__body a {
            padding: 0;
            color: var(--color-black);
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 20px;
        }
.pages__body a {
            text-decoration: underline;
        }
.pages__body h2 {
            font-weight: bold;
            text-transform: uppercase;
        }
.pages__image {
        width: auto;
        height: auto;
        margin-bottom: 30px;
    }
.pages__image img {
            -o-object-fit: cover;
               object-fit: cover;
        }
.pages__articles {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 820px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
.pages__articles .articles__card {
            margin-bottom: 30px;
        }
.pages__articles .articles__card__name {
            height: auto;
        }
.pages__articles__nav {
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            width: 100%;
            max-width: 820px;
            display: flex;
        }
.pages__products {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 925px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
    }
@media (min-width: 1440px) {
.pages__products {
            -moz-column-gap: 20px;
                 column-gap: 20px
    }
        }
@media (max-width: 1440px) {
.pages__products {
            -moz-column-gap: 10px;
                 column-gap: 10px
    }
        }
.pages__products .products__card {
            margin-bottom: 50px;
        }
.pages__products .products__card__name {
            height: auto;
        }
.pages__products__nav {
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            width: 100%;
            max-width: 925px;
            display: flex;
        }
.pages__block__selection {
        margin-top: 100px;
        -webkit-animation: pulse 1s;
                animation: pulse 1s;
    }
.pages__block__selection a {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            text-transform: uppercase;
            color: var(--color-gold);
            text-decoration: underline;
        }
.pages__block__selection a:hover {
                color: var(--color-red);
            }
.products__show .pages__container, .products__docs .pages__container, .products__specifications .pages__container, .products__additionally .pages__container, .selections__index .pages__container {
        padding-top: 20px;
    }
.products__show .pages__product__tab__nav {
        margin-bottom: 30px;
    }
.documents__container {
        position: relative;
        display: flex;
    }
.documents__list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
    }
.documents__element {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0;
    }
.documents__link {

        font-weight: normal;
        font-size: 18px;
        line-height: 1;
        display: flex;
        align-items: center;
        padding: 15px 0;
        color: var(--color-black);
    }
.documents__link:hover {
            font-weight: bold;
        }
.products__title {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 50px;
    }
.products__title h1 {
            margin: 0;
            padding: 0;

            font-weight: 300;
            font-size: 36px;
            line-height: 1.5;
            color: var(--color-black);
            border-bottom: 2px solid var(--color-gold);
        }
.products__card {
        display: flex;
        position: relative;
        height: auto;
        max-width: 295px;
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
        width: 33%;
    }
@media (max-width: 1440px) {
.products__card {
            max-width: none;
            width: 48%
    }
        }
.products__card__image {
            display: flex;


        }
.products__card__image a {
                display: block;
                text-decoration: none;
                color: var(--color-black);
            }
.products__card__image a:hover {
                    text-decoration: underline;
                }
.products__card__image a:hover img {
                        filter: grayscale(0%);
                    }
.products__card__image img {
                -o-object-fit: cover;
                   object-fit: cover;
                filter: grayscale(75%);
            }
.products__card__label {
            display: block;
            position: absolute;
            background: var(--color-gold);
            color: var(--color-white);
            font-size: 14px;
            line-height: 40px;
            letter-spacing: 0.1em;
            width: 130px;
            height: 40px;
            text-align: center;
            top: 320px;
            left: -5px;
        }
@media (max-width: 1200px) {
.products__card__label {
                top: 55%
        }
            }
.products__card__name {
            border-top: 3px solid #262626;
            padding-top: 15px;
            margin-top: 15px;
            display: block;
            background: var(--color-white);
            color: var(--color-black);
            font-size: 18px;
            line-height: 22px;
            font-weight: bold;
            text-transform: uppercase;
        }
.products__card__description {
            display: flex;
            background: var(--color-white);

            color: var(--color-black);
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
.pages__right-block .product__wrapper {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            flex-wrap: nowrap;
            width: 100%;
        }
@media (max-width: 1200px) {
.pages__right-block .product__wrapper {
                display: block
        }
            }
.pages__right-block .product__slider {
            width: 400px;
            position: relative;

        }
@media (max-width: 1200px) {
.pages__right-block .product__slider {
                width: 414px;
                margin-bottom: 30px

        }
            }
.pages__right-block .product__slider picture {
                position: relative;
                display: flex;
                justify-content: center;
                align-content: center;
            }
@media (max-width: 1200px) {
.pages__right-block .product__slider picture {
                    display: block
            }
                }
.pages__right-block .product__content {
            width: 100%;
            max-width: 520px;

            padding-top: 20px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
        }
@media (max-width: 1200px) {
.pages__right-block .product__content {
                padding-left: 0
        }
            }
.pages__right-block .product__content__title {
                position: relative;
            }
.pages__right-block .product__content__title h1 {
                    margin: 0;
                    padding: 0;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 48px;
                }
.pages__right-block .product__content__line {
                position: relative;
                max-width: 300px;
                height: 3px;
                background: var(--color-black-light-2);
                margin-top: 15px;
                margin-bottom: 20px;
            }
.pages__right-block .product__content__vendor {

                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                color: rgba(0, 0, 0, 0.5);
            }
.pages__right-block .product__content__body {
                padding-bottom: 0;
                width: 100%;
                margin: 0 0 30px;
            }
.pages__right-block .product__content__body h2, .pages__right-block .product__content__body p, .pages__right-block .product__content__body a {
                    padding: 0;
                    color: var(--color-black);
                    font-size: 18px;
                    line-height: 26px;
                    margin: 0 0 20px;
                }
.pages__right-block .product__content__body a {
                    text-decoration: underline;
                }
.pages__right-block .product__content__body h2 {
                    font-weight: bold;
                    text-transform: uppercase;
                }
.pages__right-block .product__content__shops__title h2 {
                        margin: 0;
                        padding: 0;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 38px;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        color: var(--color-gold);
                    }
.pages__right-block .product__content__shops__line {
                    width: 230px;
                    border: 1px solid #AE8567;
                    margin-bottom: 20px;
                }
.pages__right-block .product__content__shops__item {
                    margin-bottom: 10px;
                }
.pages__right-block .product__links a {
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 1;
                display: flex;
                align-items: center;
                text-align: center;
                color: var(--color-gold);
                border: 1px solid var(--color-gold);
                width: 192px;
                height: 40px;
                margin-top: 20px;
                justify-content: center;
            }
.pages__right-block .product__links a:hover {
                    color: var(--color-white);
                    background: var(--color-gold);

                }
.pages__right-block .product__bottom__wrap {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-end;
                justify-content: space-between;
            }
.slider-product .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        bottom: 50px;
    }
.slider-product .slick-dots li {
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }
.slider-product .slick-dots button {
            display: inline-block;
            padding: 0;
            width: 14px;
            height: 14px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
.slider-product .slick-active button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
.pages__product__menu {
        padding: 50px 100px;
        position: relative;
    }
.pages__product__menu__nav {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: space-around;
            width: 100%;
            height: 75px;
            border-bottom: 1px solid var(--color-black);
        }
.pages__product__menu__nav__element {
                margin: 0;
                padding: 0;
                width: 25%;
                height: 75px;
                display: flex;
                justify-content: space-evenly;
                align-content: center;
                align-items: stretch;
            }
.pages__product__menu__nav__element a {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    border-bottom: 10px solid transparent;
                    width: 100%;
                    justify-content: center;
                }
.pages__product__menu__nav__element a.active, .pages__product__menu__nav__element a:hover {
                        font-size: 18px;
                        font-weight: bold;
                    }
.pages__product__menu__nav__element a.active {
                        border-bottom: 10px solid var(--color-black);
                    }
.pages__product__tab {
    margin: 0 100px 60px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: stretch;
}
.pages__product__tab__nav {
        position: relative;
        display: flex;
        padding: 0 100px;
        justify-content: flex-end;
    }
.pages__product__tab.products {
        -moz-column-gap: 20px;
             column-gap: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
@media (max-width: 1455px) {
.pages__product__tab.products {
            -moz-column-gap: 13px;
                 column-gap: 13px
    }
        }
.pages__product__tab .products__card {
        flex: 0 0 calc(25% - 10px);
        margin-bottom: 50px;
    }
.pages__product__tab__property__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
.pages__product__tab__property__list li {
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            line-height: 28px;
        }
.pages__product__tab__property__list li span {
                font-weight: bold;
            }
.pages__product__tab__docs {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-left: 100px;
        padding-right: 100px;
    }
@media (max-width: 1200px) {
.pages__product__tab__docs {
            padding: 0
    }
        }
.pages__product__tab__docs__element {
            list-style: none;
            display: flex;
            align-items: center;
            height: 65px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            flex: 0 0 calc(33.333% - 20px);
            margin-left: 10px;
            margin-right: 10px;
        }
.pages__product__tab__docs__element a {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: var(--color-black);
                width: 100%;
            }
.pages__product__tab__docs__element a:hover {
                    font-weight: bold;
                }
.calculations__wrapper {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
.calculations__wrapper .pages__title{
            margin-bottom: 0;
        }
.js-step {
    display: none;
    bottom: 0;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
}
.js-calculation-form {


}
.step__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: var(--color-gold);
    padding: 0 0 0 10px;

}
.step__line {
    display: flex;
    width: 100%;
    height: 3px;
    background: var(--color-gold);
}
.calculations__form__select {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;
    }
.calculations__form__select .calc__select {
            display: flex;
            max-width: 300px;
        }
.calculations__form__input {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;
    }
.calculations__form__input .calc__wrap__input {
            display: flex;
            flex-direction: column;
            max-width: 295px;
            width: 100%;
        }
.calculations__form__input .calc__label {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            align-items: center;
            margin-bottom: 5px;
        }
.calculations__form__input input::-webkit-outer-spin-button,
        .calculations__form__input input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
/* Firefox */
.calculations__form__input input[type=number] {
            -moz-appearance: textfield;
        }
.calculations__form__input input {
            display: flex;
            height: 50px;
            padding-left: 20px;
            box-sizing: border-box;
            margin: 0;
            box-shadow: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 0;
            border: 1px solid var(--color-black);
            background-color: var(--color-white);
            background-repeat: no-repeat, repeat;
            background-position: right .7em top 50%, 0 0;
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0111032 9H1.4511V3.096L4.3671 6.858H4.4211L7.2291 3.096V9H8.6691V0.9H7.3911L4.4391 4.914L1.3251 0.9H0.0111032V9Z' fill='black'/%3E%3C/svg%3E%0A");
        }
.calculations__form__input input:focus {
                outline: none;
            }
.calculations__form__wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
.calculations__form__left {
        max-width: 610px;
        width: 100%;
        margin-right: 15px;

    }
.calculations__form__left .step__title {
            margin-top: 40px;
        }
.calculations__form__left .step__label {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            align-items: center;
            color: #856B5D;
            margin-top: 40px;
        }
.calculations__form__right {
        width: 100%;
        max-width: calc(100% - 625px);
        border-left: 3px solid var(--color-gold);

    }
.calculations__form__right__top {
            border-bottom: 3px solid var(--color-gold);
            padding-left: 30px;
            min-height: 96px;
        }
.calculations__form__right__top.result {
                display: flex;
                align-items: flex-end;
            }
.calculations__form__right__top .data__info {
                font-size: 36px !important;
                font-weight: bold !important;
            }
.calculations__form__right__top .data__info span {
                }
.calculations__form__right__bottom {
            padding-left: 30px;
            padding-top: 40px;
        }
.calculations__form__right .title__info {
            padding: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: baseline;
            text-transform: uppercase;

            color: var(--color-gold);
            margin: 0 0 15px;

        }
.calculations__form__right .data__info {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            display: flex;
            align-items: baseline;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 15px;
        }
.calculations__form__right .data__info span {
                font-size: 36px;
                font-weight: bold;
            }
.calculations__form__nav {
        display: flex;
        margin-top: 60px;
    }
.calculations__form__nav .btn__calc {
            margin-left: 625px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 1;
            display: flex;
            align-items: center;
            text-align: center;
            color: #856B5D;
            border: 1px solid var(--color-gold);
            width: 190px;
            height: 50px;
            justify-content: center;
            text-transform: uppercase;

        }
/* class applies to select element itself, not a wrapper element */
.select-css {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--color-black);
    padding-left: 50px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    box-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 50px;
    border-radius: 0;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 18L20.4593 0.75H0.540708L10.5 18Z' fill='black'/%3E%3C/svg%3E%0A");
    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.828' height='12.243' viewBox='0 0 18.828 12.243'%3E%3Cpath id='Контур_60927' data-name='Контур 60927' d='M1022.79-4302.8l8,8-8,8' transform='translate(-4285.387 -1021.375) rotate(90)' fill='none' stroke='%23a5a5a5' stroke-miterlimit='10' stroke-width='4'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat, repeat;
    background-position: left .7em top 50%, 0 0;
    //background-size: 18px auto, 100%;
}
.select-css::-ms-expand {
    display: none;
}
.select-css:hover {
}
.select-css:focus {
    box-shadow: 0 0 1px 1px rgb(38, 38, 38);
    box-shadow: 0 0 0 1px -moz-mac-focusring;
    outline: none;
}
.select-css option {
    font-weight:normal;
}
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}
.select-css:disabled, .select-css[aria-disabled=true] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}
.select-css:disabled:hover, .select-css[aria-disabled=true] {
}
.calculations__product__wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}
.calculations__product__wrap .products__card {
        display: flex;
        position: relative;
        height: auto;
        width: auto;
        max-width: 295px;
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 50px;
    }
.calculations__product__wrap .products__card:hover .circle__label .span{
                    width: 18px;
                    height: 18px;
                    border-radius: 18px;
                    display: block;
                    background: var(--color-red);

                }
.calculations__product__wrap .products__card .inactive {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0.8;
            z-index: 99;
            cursor: pointer;
        }
.calculations__product__wrap .products__card.active .inactive{
                width: 0;
                height: 0;
            }
.calculations__product__wrap .products__card.active .circle__label .span{
                    width: 18px;
                    height: 18px;
                    border-radius: 18px;
                    display: block;
                    background: var(--color-red);

                }
.calculations__product__wrap .products__card .circle__label{
            background: #FFFFFF;
            border: 2px solid #AE8567;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            right: 15px;
            top: 65%;
            z-index: 101;
            display: flex;
            align-items: center;
            justify-content: center;
        }
.calculations__product__wrap .products__card__image {
            display: flex;


        }
.calculations__product__wrap .products__card__image a {
                display: block;
                text-decoration: none;
                color: var(--color-black);
            }
.calculations__product__wrap .products__card__image a:hover {
                    text-decoration: underline;
                }
.calculations__product__wrap .products__card__image a:hover img {
                        filter: grayscale(0%);
                    }
.calculations__product__wrap .products__card__image img {
                -o-object-fit: cover;
                   object-fit: cover;
                filter: grayscale(75%);
            }
:root {
    --color-red: #CB2C30;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-black-light: #111820;
    --color-black-light-2: #262626;
    --color-gold: #AE8567;
    --color-grey: #E5E5E5;
    --color-grey-light: #F2F2F2;

}
.anchor-wrap {
    position: relative;
}
.anchor {
    position: absolute;
    top: -20px;
}
@-webkit-keyframes pulse {from {transform: scale3d(1, 1, 1);}50% {transform: scale3d(1.05, 1.05, 1.05);}to {transform: scale3d(1, 1, 1);}}
@keyframes pulse {from {transform: scale3d(1, 1, 1);}50% {transform: scale3d(1.05, 1.05, 1.05);}to {transform: scale3d(1, 1, 1);}}
@-webkit-keyframes fadeIn {from {opacity: 0;}to {opacity: 1;}}
@keyframes fadeIn {from {opacity: 0;}to {opacity: 1;}}

