.container__slider {
    position: relative;
    max-height: 700px;
    overflow: hidden;
}

.slider-top {
    position: relative;

    &__img-wrap {
        position: relative;

        img {
            overflow: hidden;
        }
    }

    &__logo {
        position: absolute;
        left: 100px;
        top: 70px;
        @media (max-width: 1200px) {
            left: 50px;
            top: 30px

        }

        &_svg {
            width: 190px;
            height: 60px;

            path {
                fill: var(--color-white);
            }
        }
    }

    &__body-wrap {
        position: absolute;
        height: auto;
        width: auto;
        bottom: 65px;
        left: 100px;
        display: block;
        @media (max-width: 1200px) {
            left: 50px;
            bottom: 40px;
        }


    }

    &__line {
        display: block;
        height: 2px;
        width: 400px;
        background-color: var(--color-red);
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &__title {
        position: relative;
        display: block;

        h2, .text {

            font-weight: 300;
            font-size: 64px;
            line-height: 1;
            text-transform: uppercase;
            color: var(--color-white);
            margin: 0;
            padding: 0;

            @media (max-width: 1200px) {
                font-size: 48px;

            }


        }
    }

    &__description {
        position: relative;
        display: block;
        max-width: 820px;

        p {
            margin: 0;
            padding: 0;

            font-weight: normal;
            font-size: 20px;
            line-height: 22px;
            color: var(--color-white);


            @media (max-width: 1200px) {
                font-size: 18px;
                line-height: 20px;

            }
        }
    }

    &__link {
        display: block;
        height: auto;
        width: auto;
        position: relative;
        margin-top: 65px;
    }
}


.slider-top-dots {
    position: absolute;
    top: 50%;
    right: 130px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin: 0;
    list-style: none;
    @media (max-width: 1200px) {
       right: 72px;
    }

    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        li {
            text-align: center;
            margin-bottom: 17px;
            margin-top: 17px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
    }
}
