.calculations{
    &__wrapper {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .pages__title{
            margin-bottom: 0;
        }
    }
}
