.header {
    position: fixed;
    height: auto;
    width: 100%;
    max-width: 1440px;
    top: 60px;
    z-index: 1000;
    display: grid;
    margin: 0 auto;
    @media (max-width: 1200px) {
        top: 30px
    }

    &__logo {
        display: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    &__menu {
        display: flex;
        width: 90%;
        height: 80px;
        background-color: var(--color-red);
        border-bottom-right-radius: 40px;
        border-top-right-radius: 40px;
        opacity: 1;
        transition: width 0.3s ease-out, opacity 0.3s ease-out, height 0.7s ease;

        &--closed {
            width: 0;
            height: 0;
            opacity: 0;

            .header__nav {
                margin-left: -500px;
                transition: margin-left 0.3s ease-out;
            }

            .header__list {
                margin-left: -500px;
                transition: margin-left 0.3s ease-out;
            }
        }
    }

    &__button {
        position: relative;
        display: flex;
        list-style: none;
        width: 80px;
        height: 80px;
        margin-right: 100px;
        margin-left: 40px;
        z-index: 999;
        right: 0;
        top: 0;

        @media (max-width: 1200px) {
            margin-right: 40px;
        }


        &--closed {
            display: flex;
            position: absolute;
            right: 100px;
            top: 0;
            margin-right: 0;
            margin-left: 40px;
            @media (max-width: 1200px) {
                right: 40px;
            }
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        width: 100%;
        transition: all 0.5s ease-out;
    }

    &__list {
        transition: all 0.5s ease-out;
        margin: 0 40px;
        width: 100%;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        line-height: 1;
        font-size: 18px;
        font-weight: bold;

        li {
            margin-right: auto;
            margin-left: auto;
            line-height: 1;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

        }

        a {
            text-transform: uppercase;
            display: inline-block;
            font-stretch: normal;

            letter-spacing: normal;
            text-align: left;
            text-decoration: none;
            padding-top: 7px;
            padding-bottom: 7px;
            color: var(--color-white);

            &:hover, &:focus, &.active {
                text-decoration: underline;
            }
        }
    }
}
