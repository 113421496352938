
.icon__slides {
    position: relative;
    background: var(--color-red);
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 5px solid var(--color-gold);

    &.product {
        margin-left: 100px;
        margin-right: 100px;

        .icon__slides__wrap {
            padding-top: 0;
        }
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;

        h1, .head__title {
            margin: 0;
            padding: 0;
            font-weight: 300;
            font-size: 36px;
            line-height: 1.5;
            color: var(--color-white);
            border-bottom: 2px solid var(--color-gold);
        }

    }

    &__wrap {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
    }

    &__image {
        flex: 1 1 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
    }

    &__name {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 10px;

        p {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: var(--color-white);
        }
    }
}
