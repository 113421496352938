*,
*::before,
*::after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

main, section, article, header {
    display: block;
}
a {
    background-color: transparent;
    text-decoration: none;
}
