.pages__right-block {
    .product {
        &__wrapper {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            flex-wrap: nowrap;
            width: 100%;
            @media (max-width: 1200px) {
                display: block;
            }
        }

        &__slider {
            width: 400px;
            position: relative;
            @media (max-width: 1200px) {
                width: 414px;
                margin-bottom: 30px;
            }
            picture {
                position: relative;
                display: flex;
                justify-content: center;
                align-content: center;
                @media (max-width: 1200px) {
                    display: block;
                }
            }

        }

        &__content {
            width: 100%;
            max-width: 520px;

            padding-top: 20px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            @media (max-width: 1200px) {
                padding-left: 0;
            }
            &__title {
                position: relative;

                h1 {
                    margin: 0;
                    padding: 0;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            &__line {
                position: relative;
                max-width: 300px;
                height: 3px;
                background: var(--color-black-light-2);
                margin-top: 15px;
                margin-bottom: 20px;
            }

            &__vendor {

                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                color: rgba(0, 0, 0, 0.5);
            }

            &__body {
                padding-bottom: 0;
                width: 100%;
                margin: 0 0 30px;

                h2, p, a {
                    padding: 0;
                    color: var(--color-black);
                    font-size: 18px;
                    line-height: 26px;
                    margin: 0 0 20px;
                }

                a {
                    text-decoration: underline;
                }

                h2 {
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }

            &__shops {
                &__title {
                    h2 {
                        margin: 0;
                        padding: 0;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 38px;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        color: var(--color-gold);
                    }
                }

                &__line {
                    width: 230px;
                    border: 1px solid #AE8567;
                    margin-bottom: 20px;
                }

                &__item {
                    margin-bottom: 10px;
                }
            }
        }

        &__links {
            a {
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 1;
                display: flex;
                align-items: center;
                text-align: center;
                color: var(--color-gold);
                border: 1px solid var(--color-gold);
                width: 192px;
                height: 40px;
                margin-top: 20px;
                justify-content: center;

                &:hover {
                    color: var(--color-white);
                    background: var(--color-gold);

                }
            }

        }

        &__bottom {
            &__wrap {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-end;
                justify-content: space-between;
            }

        }
    }
}
.slider-product{
    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        bottom: 50px;
        li {
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 14px;
            height: 14px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
    }
}

.pages__product {
    &__menu {
        padding: 50px 100px;
        position: relative;


        &__nav {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: space-around;
            width: 100%;
            height: 75px;
            border-bottom: 1px solid var(--color-black);

            &__element {
                margin: 0;
                padding: 0;
                width: 25%;
                height: 75px;
                display: flex;
                justify-content: space-evenly;
                align-content: center;
                align-items: stretch;

                a {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    border-bottom: 10px solid transparent;
                    width: 100%;
                    justify-content: center;

                    &.active, &:hover {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    &.active {
                        border-bottom: 10px solid var(--color-black);
                    }
                }
            }
        }
    }

}

.pages__product__tab {
    margin: 0 100px 60px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: stretch;


    &__nav {
        position: relative;
        display: flex;
        padding: 0 100px;
        justify-content: flex-end;
    }

    &.products {
        column-gap: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
        @media (max-width: 1455px) {
            column-gap: 13px;
        }
    }

    .products__card {
        flex: 0 0 calc(25% - 10px);
        margin-bottom: 50px;
    }

    &__property__list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            line-height: 28px;

            span {
                font-weight: bold;
            }
        }
    }

    &__docs {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-left: 100px;
        padding-right: 100px;
        @media (max-width: 1200px) {
            padding: 0;
        }
        &__element {
            list-style: none;
            display: flex;
            align-items: center;
            height: 65px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            flex: 0 0 calc(33.333% - 20px);
            margin-left: 10px;
            margin-right: 10px;

            a {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: var(--color-black);
                width: 100%;
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
}
