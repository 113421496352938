.slick-slide {
  font-size: 0;
}


.slick-center {
  font-size: 3em;
}


.slick-dots {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    li {
        text-align: center;
        margin-bottom: 17px;
        margin-top: 17px;
    }

    button {
        display: inline-block;
        padding: 0;
        width: 13px;
        height: 13px;
        background-color: var(--color-red);
        border-radius: 50%;
        font-size: 0;
        line-height: 0;
        outline: none;
        border: none;
        cursor: pointer;
    }
}

.slick-active {
    button {
        width: 20px;
        height: 20px;
        background-color: var(--color-red);
    }
}
