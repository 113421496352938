.partners {
    position: relative;
    background: var(--color-white);
    padding: 60px;

    &__slides {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__card {
        position: relative;
        object-fit: contain;

        &__image {

        }

        &__body-wrap {
            position: absolute;
            height: auto;
            width: auto;
            bottom: 65px;
            left: 100px;
            display: block;
            color: var(--color-white);

            @media (max-width: 1200px) {
                bottom: 40px;
                left: 50px;
            }
        }

        &__title {
            position: relative;
            display: block;
            max-width: 550px;

            h2, .text {

                font-weight: 300;
                font-size: 48px;
                line-height: 1;
                text-transform: uppercase;
                color: var(--color-white);
                margin: 0;
                padding: 0;

                @media (max-width: 1200px) {
                    font-size: 36px;
                }
            }


        }

        &__line {
            display: block;
            height: 2px;
            width: 500px;
            background-color: var(--color-red);
            margin-top: 15px;
            margin-bottom: 15px;

        }

        &__description {
            text-transform: uppercase;
            max-width: 550px;

            p {
                text-transform: uppercase;
                margin: 0;
                padding: 0;

                font-weight: bold;
                font-size: 18px;
                line-height: 1.1;
                color: var(--color-white);
                @media (max-width: 1200px) {
                    font-size: 14px;
                }
            }
        }

        &__link {
            display: block;
            height: auto;
            width: auto;
            position: relative;
            margin-top: 40px;
            @media (max-width: 1200px) {
                margin-top: 20px;
            }
        }
    }

}
