.footer {
    padding: 50px 100px;
    background-color: var(--color-white);
    border: none;
    display: grid;
    grid-template-columns: minmax(122px, 210px) minmax(210px, 420px) minmax(120px, 210px) minmax(250px, 400px);
    @media (max-width: 1200px) {
        padding: 40px 50px;
    }

    &--logo {
        position: relative;

        a {
            display: block;
        }

        svg {
            width: 122px;
            height: 40px;

            path {
                fill: var(--color-black);
            }
        }
    }

    &__menu {
        h5 {
            margin: 0;
            padding: 0;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;

        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 18px;
            font-weight: normal;
            line-height: 1;

            li {
                display: block;
                margin-bottom: 10px;

                a {
                    color: var(--color-black);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &--social {
            h5 {
                text-align: center;
            }
        }
    }


    &__social {
        text-align: center;

        .social {
            flex-direction: row;
            align-items: center;

            &__link {
                path {
                    fill: var(--color-black) !important;
                }

                &:not(:last-of-type) {
                    margin: 0 8px 0 0;
                }

                &:hover {
                    path {
                        fill: var(--color-red) !important;
                    }
                }
            }
        }

    }

    &__oba {
        display: flex;
        width: 100%;
        justify-content: center;
        span {
            align-items: center;
            font-size: 12px;
            color: var(--color-black);
        }
        a {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: var(--color-black);

            span {
                margin-right: 10px;

            }
            svg{
                font-size: 12px;
                width: 100px;
            }
        }
    }
}
