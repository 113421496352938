.catalog__menu {
    position: relative;
    height: 120px;
    background: var(--color-black-light);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 1200px) {
        height: 85px;
    }

    &__element {
        height: 120px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--color-gold);
        width: 50%;
        order: 1;

        @media (max-width: 1200px) {
            height: 85px;
        }

        &.main{
            &:first-of-type {
                padding-left: 100px;
            }
            width: 50%;
        }


        &.active {
            width: 70% !important;
            background: var(--color-red);
            color: var(--color-white);
            order: 0;
            padding-left: 100px !important;

            .catalog__menu__link {
                border-left: 4px solid var(--color-white);
                color: var(--color-white);
            }

        }

        &:hover {
            background: var(--color-red);
            color: var(--color-white);

            .catalog__menu__link {
                border-left: 4px solid var(--color-white);
                color: var(--color-white);
            }
        }
    }

    &__link {
        border-left: 4px solid var(--color-gold);
        padding-left: 30px;
        padding-right: 30px;

        font-weight: 300;
        font-size: 36px;
        line-height: 1;
        display: flex;
        width: 100%;
        height: 90px;
        align-items: center;
        text-transform: uppercase;
        color: inherit;
        text-decoration: none;

        @media (max-width: 1200px) {
            font-size: 24px;
            height: 85px;
        }
    }
}

.catalog__menu.product {
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
    background: var(--color-grey-light);
    border-bottom: 2px solid var(--color-black);
    @media (max-width: 1200px) {
        margin-left: 40px;
        margin-right: 40px;
    }

    .catalog__menu__element {
        background: var(--color-grey-light);
        color: var(--color-gold);

        &:first-of-type {
            padding-left: 0 !important;
            width: 55%;

        }

        &.active {
            width: 75% !important;
            background: var(--color-white);
            color: var(--color-gold);
            order: 0;
            padding-left: 0 !important;

            .catalog__menu__link {
                border-left: 4px solid var(--color-gold);
                color: var(--color-gold);
                font-size: 36px;
            }

        }

        &:hover {
            background: var(--color-red);
            color: var(--color-white);

            .catalog__menu__link {
                border-left: 4px solid var(--color-gold);
                color: var(--color-white);
            }
        }

    }

    .catalog__menu__link {
        font-size: 18px;
    }

}
