@font-face {
  font-family: 'Circe';
  src: url('../fonts/Circe-Regular.eot');
  src: local('Circe'), local('Circe-Regular'),
  url('../fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Circe-Regular.woff') format('woff'),
  url('../fonts/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('../fonts/Circe-Light.eot');
  src: local('Circe Light'), local('Circe-Light'),
  url('../fonts/Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Circe-Light.woff') format('woff'),
  url('../fonts/Circe-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('../fonts/Circe-Bold.eot');
  src: local('Circe Bold'), local('Circe-Bold'),
  url('../fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Circe-Bold.woff') format('woff'),
  url('../fonts/Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
