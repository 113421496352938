.calculations__product__wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .products__card {
        display: flex;
        position: relative;
        height: auto;
        width: auto;
        max-width: 295px;
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 50px;
        &:hover{
            .circle__label{
                .span{
                    width: 18px;
                    height: 18px;
                    border-radius: 18px;
                    display: block;
                    background: var(--color-red);

                }
            }
        }
        .inactive {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0.8;
            z-index: 99;
            cursor: pointer;
        }

        &.active{
            .inactive{
                width: 0;
                height: 0;
            }
            .circle__label{

                .span{
                    width: 18px;
                    height: 18px;
                    border-radius: 18px;
                    display: block;
                    background: var(--color-red);

                }
            }
        }
        .circle__label{
            background: #FFFFFF;
            border: 2px solid #AE8567;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            right: 15px;
            top: 65%;
            z-index: 101;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__image {
            display: flex;

            a {
                display: block;
                text-decoration: none;
                color: var(--color-black);

                &:hover {
                    text-decoration: underline;

                    img {
                        filter: grayscale(0%);
                    }
                }
            }

            img {
                object-fit: cover;
                filter: grayscale(75%);
            }


        }
    }
}
