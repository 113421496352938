.pages {
    &__title {
        h1, .head__title {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            color: var(--color-red);
        }
    }

    &__container {
        padding: 50px 100px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: space-between;
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 1200px) {
            padding: 40px 50px;
        }

    }

    &__left-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;

        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 300px;

        @media (max-width: 1200px) {
            flex-basis: 250px;
        }

        ul {
            margin: 30px 0 0;
            padding: 0;
            list-style: none;
            width: 240px;

            .top__list__block {
                display: none;
                margin-top: 0;
                margin-left: 10px;
                animation: pulse 1s;

                a {
                    font-weight: normal !important;
                }

                li {
                    &:last-child {
                        border: none;
                    }
                }

                li.active a {
                    font-weight: bold !important;
                }

                &.active {
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;
                    animation: fadeIn 1s;
                }
            }

            li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);

                &:hover, &.active {
                    a, button {
                        font-weight: bold;
                    }

                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }

                a, button {
                    padding: 15px 0;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    width: 260px;

                    @media (max-width: 1200px) {
                        font-size: 20px;
                        width: 230px;
                    }

                }
            }

            &.bottom__list {
                margin-top: 100px;

                li {
                    border: none;

                    &:hover {
                    //border: none;
                    }
                }

                .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: var(--color-black-light);
                    text-transform: uppercase;
                    border-bottom: 2px solid var(--color-black-light);
                    margin-bottom: 30px;
                    @media (max-width: 1200px) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }

                .btn__list__nav {
                    padding: 15px 0;
                    text-align: left;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    width: 100%;

                    &.active {
                        font-weight: bold;
                        border-bottom: 2px solid var(--color-black);
                        margin-bottom: 10px;
                        margin-top: 30px;
                    }

                }

                .list__block {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    margin-bottom: 10px;
                    animation: fadeIn 0.2s;

                    .list__block__menu {
                        margin: 0;
                        padding: 0;
                        height: 0;
                        display: flex;
                        opacity: 0;
                        visibility: hidden;

                        li {
                            margin: 0;
                            padding: 0;
                            width: 0;
                        }
                    }


                    &.active {
                        animation: fadeIn 0.5s;
                        border-bottom: none;
                        margin-bottom: 0;

                        .list__block__menu {
                            animation: fadeIn 1s;
                            height: auto;
                            opacity: 1;
                            visibility: visible;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;

                            li {
                                width: 100%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                display: block;

                                a {
                                    display: block;
                                    padding: 15px 0;
                                    font-weight: normal;
                                    font-size: 18px;
                                    line-height: 1;
                                    align-items: center;
                                    text-transform: uppercase;
                                    width: 100%;
                                    color: var(--color-black);
                                }

                                &:hover, &.active {
                                    a {
                                        font-weight: bold;
                                    }
                                }

                                &.active {
                                    animation: pulse 0.2s;
                                //border-bottom: 2px solid var(--color-black);

                                }
                            }
                        }
                    }


                    &:hover {
                        .btn__list__nav {
                            font-weight: bold;
                        }
                    }
                }

                .list__element {
                    margin-bottom: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;

                    &:hover, &.active {
                        a {
                            font-weight: bold;
                        }
                    }

                    &.active {
                        animation: pulse 0.2s;
                        border-bottom: 2px solid var(--color-black);

                    }


                    a {
                        display: block;
                        padding: 15px 0;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 1;
                        align-items: center;
                        text-transform: uppercase;
                        width: 100%;
                        color: var(--color-black);
                    }
                }
            }

        }

        .pages__title {
            border-bottom: 2px solid var(--color-red);
            min-width: 240px;
        }

        h1, .head__title {

            font-weight: bold;
            font-size: 32px;
            line-height: 1.1;
            text-transform: uppercase;

            @media (max-width: 1200px) {
                font-size: 26px;
            }
        }
    }

    &__right-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding-left: 15px;

        .pages__title {
            margin-bottom: 30px;
        }

        h1 {

            font-weight: 300;
            font-size: 32px;
            line-height: 1.1;
            text-transform: uppercase;
            color: #000000;

            @media (max-width: 1200px) {
                font-size: 26px;
                line-height: 24px;
            }
        }

    }

    &__body {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 820px;

        h2, p, a {
            padding: 0;
            color: var(--color-black);
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 20px;
        }

        a {
            text-decoration: underline;
        }

        h2 {
            font-weight: bold;
            text-transform: uppercase;
        }

    }

    &__image {
        width: auto;
        height: auto;
        margin-bottom: 30px;

        img {
            object-fit: cover;
        }
    }

    &__articles {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 820px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .articles__card {
            margin-bottom: 30px;
        }

        .articles__card__name {
            height: auto;
        }

        &__nav {
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            width: 100%;
            max-width: 820px;
            display: flex;
        }
    }


    &__products {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 925px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        @media (min-width: 1440px) {
            column-gap: 20px;
        }
        @media (max-width: 1440px) {
            column-gap: 10px;
        }

        .products__card {
            margin-bottom: 50px;
        }

        .products__card__name {
            height: auto;
        }

        &__nav {
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            width: 100%;
            max-width: 925px;
            display: flex;
        }
    }


    &__block__selection {
        margin-top: 100px;
        animation: pulse 1s;

        a {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            text-transform: uppercase;
            color: var(--color-gold);
            text-decoration: underline;

            &:hover {
                color: var(--color-red);
            }
        }
    }
}

.products__show, .products__docs, .products__specifications, .products__additionally, .selections__index {
    .pages__container {
        padding-top: 20px;
    }
}

.products__show {
    .pages__product__tab__nav {
        margin-bottom: 30px;
    }
}
